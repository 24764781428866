.buttons-container {
  display: flex;
  column-gap: 8px;
  row-gap: 8px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}
.possible-button {
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #f7bf53;
  border: 2px solid #f7bf53;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: all 0.3s ease;
  box-shadow: 0px 2px 8px 0px rgba(36, 65, 231, 0.3);
}

.possible-button:hover {
  background-color: #ffffff;
  color: #f7bf53;
  box-shadow: none;
  outline: none;
  text-decoration: none;
}