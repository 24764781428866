textarea {
  margin: 0;
  width: 90%;
  line-height: 16px;
  font-family: inherit;
  font-size: 16px;
  color: #888;
  border: 0;
  outline: 0 !important;
  resize: none;
  overflow: hidden;
}

.send-button {
  cursor: pointer;
  background: none;
  border: none;
  padding: 2px;
  color: #007bc4;
  width: 60px;
  height: 60px;
  position: absolute;
  bottom: 42px;
  right: 6px;
}

.chatbox {
  position: relative;
  bottom: 50px;
  left: 55px;
  display: flex;
  flex-direction: column;
  border: 1px solid #e3e3e3;
  border-radius: 16px;
  overflow: hidden;
  width: 377px;
  height: auto;
  max-height: 0;
  max-width: 0;
  transition: max-width 1.2s, max-height .8S;
  background-color: #fff;
  box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
}

@media screen and (max-width: 480px) {
  .chatbox {
    width: 94%;
    bottom: -24px;
    left: -12px;
  }
}

.chatbox-demo {
  max-height: fit-content;
  min-width: 400px;
}

.chatbox-opened {
  transition: max-width .8s, max-height 1.2s;
  max-height: 500px;
  max-width: 500px;
}

.chatbox-closed {
  width: 70%;
  transition: max-width .8s, max-height 1.2s;
  max-height: 200px;
  max-width: 300px;
  cursor: pointer;
}

header {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  width: 100%;
  padding: 16px;
  color: #fff;
  font-size: 13px;
  background-color: #007bc4;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

.housesContainer {
  display: flex;
  height: 30px;
  width: auto;
}

.housesContainer img {
  width: 30px;
}

.main {
  box-sizing: border-box;
  width: 100%;
  padding: 32px 16px;
  line-height: 24px;
  color: #888;
  text-align: center;
  max-height: 350px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.chatbox-closed .main {
  font-weight: 600;
  padding: 16px 8px;
  overflow: hidden;
}

.main-demo {
  max-height: fit-content;
}

.grow-wrap {
  /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
  display: grid;
}
.grow-wrap::after {
  /* Note the weird space! Needed to preventy jumpy behavior */
  content: attr(data-replicated-value) " ";

  /* This is how textarea text behaves */
  white-space: pre-wrap;

  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
}
.grow-wrap > textarea {
  /* You could leave this, but after a user resizes, then it ruins the auto sizing */
  resize: none;

  /* Firefox shows scrollbar on growth, you can hide like this. */
  overflow: hidden;
}
.grow-wrap > textarea,
.grow-wrap::after {
  /* Identical styling required!! */
  padding: 0.5rem;
  font: inherit;

  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;
}

footer {
  box-sizing: border-box;
  padding: 16px;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.is-working .chat-submit {
  color:grey;
}

.chat-submit {  
  position:absolute;
  bottom:3px;
  right:10px;
  background: transparent;
  box-shadow:none;
  border:none;
  border-radius:50%;
  color:#5A5EB9;
  width:35px;
  height:35px;  
}

.grecaptcha-badge {
  visibility: hidden;
}

.reCaptchaBranding {
  color: #888;
  font-size: 11px;
  margin-top: 4px;
}

.message {
  padding:10px 14px;  
  color:#ffffff;
  max-width:75%;
  float:left;
  position:relative;
  margin: 0 0 20px 10px;
  border-radius:10px;
  text-align: left;
  overflow-wrap: break-word;
}

.message-container {
  display: flex;
  justify-content: flex-start;
}

.message-container .message {
  background-color: #007bc4;
}

.response-container {
  display: flex;
  justify-content: flex-end;
}

.response-container .message {
  background-color: #8a8a8a;
}

/*
$base-height: 377px;
$base-dimension: 16px;
$base-border-radius: 12px;
$base-button-size: 52px;
$primary-color: #007bc4;
$accent-color: #f6f7f9;
$background-color: #fff;
$font-color: #888;
*/