.chatbot {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

@media screen and (max-width: 480px) {
  .chatbot {
    flex-direction: column;
  }
}