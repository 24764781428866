.icon-container {
  display: flex;
  flex-direction: column;
  max-width: 110px;
  justify-content: center;
  cursor: pointer;
}

.houses-container {
  display: flex;
  justify-content: center;
  padding: 0 12%;
  transform: translateY(55%);
}

.platform {
  z-index: -1;  
}

.hithere {
  animation: hithere 1.2s ease forwards;
  animation-delay: 2s;
}
@keyframes hithere {
  30% { transform: scale(1.2); }
  40%, 60% { transform: rotate(-20deg) scale(1.2); }
  50% { transform: rotate(20deg) scale(1.2); }
  70% { transform: rotate(0deg) scale(1.2); }
  100% { transform: scale(1); }
}