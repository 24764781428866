.valuation div:not(.lds-ellipsis) {
  display: flex;
  color: #007bc4;
}

.valuation {
  flex-direction: column;
  margin: 0 0 20px 10px;
  border: 2px solid #007bc4;
  border-radius: 8px;
  padding: 14px;
  gap: 8px;
}

.valuation .titleContainer {
  text-align: left;
  margin: 4px 0 12px 6px;
}

.valuation h2,
.valuation h3 {
  margin: 0;
}

.valuation .valueRange {
  flex-direction: column;
}

.valuation .value {
  margin-bottom: 12px;
}

.valuation .value,
.valuation .range {
  justify-content: center;
  flex-grow: 3;
}

.valuation img {
  max-width: 34.02px;
  max-height: 36.86px;
  flex-grow: 1;
}

.housesContainer {
  display: flex;
  height: 30px;
  width: auto;
}

.housesContainer img {
  width: 30px;
}

