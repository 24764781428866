.linkButton {
  background: none;
  border: none;
  font-size: 15px;
  text-decoration: underline;
  color: #ffffff;
  cursor: pointer;
  flex-grow: 3;
  margin-top: 8px;
}

.linkButton:hover, .wrapper.form > .linkButton:hover {
  color:#bcbcbc;
}

.wrapper.form .message-container {
  justify-content: center;
}

.wrapper.form .message {
  margin: 0 auto 20px;
}

.wrapper.form > .linkButton {
  color: #a3a3a3;
}