.reno-projects {
  flex-direction: column;
  margin: 0 0 20px 10px;
  border: 2px solid #489579;
  border-radius: 8px;
  padding: 10px;
  gap: 8px;
}

.reno-projects div:not(.lds-ellipsis) {
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: left;
  color: #489579;
}

.reno-projects .header {
  flex-direction: row !important;
  margin-bottom: 2px;
}

.reno-projects h3 {
  margin-left: 10px;
  flex-grow: 2;
  margin: 2px 0;
  text-align: left;
}

.reno-projects img {
  max-width: 34.02px;
  max-height: 36.86px;
  flex-grow: 1;
}

.reno-projects thead tr {
  line-height: 18px;
}

.reno-projects tbody tr {
  line-height: 16px;
  font-size: 15px;
}